.heart {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url('../../images/like.png');
  cursor: pointer;
  margin: -25px -15px;
}
.heart-blast {
  background-position: -1680px 0;
  transition: background 1s steps(28);
}

.items-table {
  tbody {
    tr {
      padding: 0;
      border-radius: $radius;
      position: relative;
      box-shadow: 0 1px 0 0 #f8f8f8;
      @include transitionMedium;
      .dropdown {
        opacity: 0;
      }
      &.active,
      &:hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        z-index: 1;
        .dropdown {
          opacity: 1;
        }
      }
      th,
      td {
        border: 0;
        padding: 18px 8px;
      }
    }
  }
  @include respond('phone') {
    width: 500px;
  }
}

.star-review {
  i {
    font-size: 16px;
    &.text-gray {
      color: #d9d9d9;
    }
  }
  span {
    color: $black;
  }
}

// Form Head
.form-head {
  .breadcrumb {
    background: transparent;
    padding: 0;
  }
  .search-area {
    max-width: 300px;
    border-radius: 0;
    @include respond('laptop') {
      max-width: 250px;
    }
    @include respond('phone') {
      max-width: 250px;
    }
    .form-control {
      border: 0;
      height: 56px;
      padding: 10px 15px;
      font-size: 16px;
      background: #f1f1f1;
      border-radius: 0;
      @include respond('laptop') {
        height: 41px;
        padding: 10px 15px;
        font-size: 13px;
      }
    }
    .input-group-append .input-group-text {
      background: #f1f1f1;
      padding: 0 5px 0 30px;
      @include respond('laptop') {
        padding: 0 5px 0 30px;
      }
      i {
        font-size: 20px;
        color: $body-color;
        @include respond('laptop') {
          font-size: 18px;
        }
      }
    }
  }
  .dropdown {
    .btn {
      i {
        transform: scale(1.3);
        -moz-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        display: inline-block;
      }
    }
    &:after {
      color: var(--primary);
      font-size: 25px;
    }
  }
  .btn i {
    line-height: 1;
    transform: scale(1.3);
    display: inline-block;
    margin-right: 5px;
  }
  .btn-outline-primary {
    border-color: #eee;
    &:hover {
      border-color: var(--primary);
    }
  }
  & > * {
    @include respond('phone-land') {
      margin-right: 5px !important;
      margin-bottom: 10px;
      vertical-align: baseline;
      margin-left: 0 !important;
    }
  }
  @include respond('phone-land') {
    display: block !important;
  }
}
.rounded {
  border-radius: $radius !important;
}
.btn-link {
  font-weight: 500;
  font-size: 16px;
  @include respond('laptop') {
    font-size: 14px;
  }
}
.deznav .copyright {
  font-size: 14px;
  padding: 0 30px;
  margin-top: 40px;
  color: $black;
  margin-bottom: 40px;

  p {
    margin-bottom: 10px;
    strong {
      display: block;
    }
  }

  @include respond('laptop') {
    padding: 0 25px 50px;
  }
}
.widget-buyer {
  .media {
    img {
      width: 30px;
    }
    .media-body {
      h5 {
        font-size: 14px;
        margin-bottom: 0px;
      }
      p {
        font-size: 13px;
      }
    }
  }
}

.recentOrderTable {
  table {
    margin-bottom: 0;
    overflow: hidden;
    thead {
      th {
        font-size: 12px;
      }
    }
    tbody {
      td {
        color: $dark;
        font-weight: 500;
        line-height: 40px;
        @at-root [data-theme-version='dark'] & {
          color: $d-ctl;
        }
        img {
          border-radius: 50px;
        }
        .custom-dropdown {
          i {
            padding: 0 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.activity_overview {
  .nav-tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  }
  .custom-tab-1 .nav-link {
    color: rgba(255, 255, 255, 0.5);
    padding-left: 0;
    padding-right: 0;
    margin-right: 30px;

    &:hover,
    &.active {
      background: transparent;
      color: #fff;
      border-color: #fff;
    }
  }
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9;
}

.chart-link {
  @include respond('phone') {
    display: flex;
    align-items: center;
  }
  @include respond('tab-land') {
    display: flex;
    align-items: center;
  }
  @include respond('desktop') {
    display: flex;
    align-items: center;
  }
  .week-link {
    display: inline-block;
    // @include respond('tab-land'){
    //     display: flex;
    // }
  }
  a {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
    i {
      font-size: 13px;
      &.text-primary {
        color: var(--primary);
      }
      &.text-muted {
        color: #dae2f3 !important;
      }
    }
  }
  .form-control {
    max-width: 97px;
    display: inline-block;
  }
}
.input-group {
  &.input-group-lg {
    .form-control,
    .input-group-text {
      height: 80px;
      padding: 10px 30px;
      @include respond('phone') {
        height: 50px;
        padding: 10px 15px;
        font-size: 14px;
      }
    }
    .input-group-text {
      width: 200px;
      text-align: left;
      @include respond('phone') {
        width: 120px;
        padding: 10px 15px;
        height: 50px;
        font-size: 14px;
      }
    }
  }
}
.card-tabs {
  .nav-tabs {
    border-bottom: 0px;
    background: $light;
    padding: 0;
    border-radius: 0;
    flex-wrap: unset;

    .nav-link {
      border-radius: 0;
      padding: 12px 20px;
      font-weight: 500;
      font-size: 14px;
      border: 0;
      &.active {
        background: var(--primary);
        color: $white;
      }
      @include respond('laptop') {
        padding: 8px 15px;
        font-weight: 400;
        font-size: 13px;
      }
    }
    &.tabs-lg {
      .nav-link {
        padding: 23px 30px;
        font-size: 16px;
        @include respond('phone-land') {
          padding: 10px 15px;
          font-size: 14px;
        }
      }
    }
  }
  &.icontabs {
    .nav-tabs {
      background: transparent;
      .nav-item {
        .nav-link {
          height: 42px;
          width: 42px;
          line-height: 42px;
          text-align: center;
          background: rgba($gray, 0.6);
          color: $white;
          margin-right: 15px;
          border-radius: 50px;
          padding: 0;
          &.active {
            background: $warning;
          }
          i {
            font-size: 25px;
            line-height: 42px;
          }
        }
      }
    }
  }
}

.revenue-chart .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
  display: flex !important;
  margin-right: 30px !important;
  margin-bottom: 30px !important;
}
.revenue-chart-bar {
  margin-bottom: -30px;

  @include respond('phone') {
    margin-bottom: 20px;
    float: none;
    padding-left: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .revenue-chart-bar {
    margin-bottom: 20px;
    float: none;
    padding-left: 20px;
  }
}
#activeUser {
  height: 215px !important;
}
#activeUser {
  height: 180px !important;
}
span#counter {
  font-size: 30px;
  font-weight: 700;
  color: var(--primary);
}
.social-graph-wrapper {
  text-align: center;
  padding: 20px;
  position: relative;
  color: $white;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  &.widget-facebook {
    background-color: $facebook;
  }
  &.widget-twitter {
    background-color: $twitter;
  }
  &.widget-linkedin {
    background-color: $linkedin;
  }
  &.widget-googleplus {
    background-color: $google-plus;
  }
  .s-icon {
    font-size: 24px;
    position: relative;
    // top: 0.625rem;
    padding: 0 10px;
  }
}
.recent-stats i {
  font-size: 11px;
  position: relative;
  top: -1px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  #lineChart_3Kk {
    height: 140px !important;
  }
}
/* add menu sidebar */
.add-menu-sidebar {
  background: var(--primary);
  padding: 40px 20px 20px;
  border-radius: 0;
  position: relative;
  margin: 50px 50px 30px;
  @include respond('laptop') {
    margin: 50px 25px 30px;
  }
  @include respond('phone') {
    margin: 20px 25px 30px;
  }

  p {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 300;
    color: #fff;
  }
  &:after {
    position: absolute;
    background-image: url('../../images/dots.svg');
    background-repeat: no-repeat;
    bottom: 10px;
    right: 20px;
    content: '';
    height: 30px;
    width: 25px;
  }
}

.donut-chart-sale {
  position: relative;
  small {
    font-size: 16px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: center;
    font-weight: 600;
  }
}
.profile-bx {
  border-bottom: 1px solid $border-color;
  .profile-image {
    background-image: url('../../images/circle.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    img {
      margin: 18px;
      width: 118px;
    }
  }
  @include respond('tab-land') {
    border-bottom: 0;
  }
  @include respond('phone-land') {
    border-bottom: 1px solid $border-color;
  }
}

//search-area
.search-area {
  width: 300px;
  .form-control {
    background: #e3e3e3;
    border-width: 0px;
    padding-left: 20px;
    border-radius: 60px 0 0 60px;
  }
  .input-group-text {
    background: #e3e3e3;
    border-width: 0px;
    padding-right: 25px;
    border-radius: 0 60px 60px 0;
  }
  .form-control {
    height: 42px;
  }

  @include respond('tab-port') {
    width: 200px;
  }
}
.contact-icon {
  height: 50px;
  width: 50px;
  min-width: 50px;
  border: 1px solid var(--rgba-primary-2);
  color: var(--primary);
  font-size: 23px;
  border-radius: 100%;
  line-height: 50px;
  text-align: center;

  @include respond('laptop') {
    height: 41px;
    width: 41px;
    line-height: 41px;
    min-width: 41px;
    font-size: 18px;
  }
}

.toogle-bx {
  height: 16px;
  position: relative;
  width: 33px;
  @include transitionMedium;
  background: #d8d8d8;
  border-radius: 23px;
  span {
    @include transitionMedium;
    background: #959595;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    left: -10px;
    border-radius: 100%;
  }
  &.active {
    @include transitionMedium;
    background: var(--rgba-primary-3);
    span {
      @include transitionMedium;
      left: 18px;
      background: var(--primary);
    }
  }
}

.min-w30 {
  min-width: 30px;
}
.min-w32 {
  min-width: 32px;
}
.min-w42 {
  min-width: 42px;
}
.min-w46 {
  min-width: 46px;
}
.min-w50 {
  min-width: 50px;
}

.search-filter {
  @include respond('phone') {
    .dropdown.custom-dropdown {
      margin: 0 15px 0 0 !important;
    }
    a.mb-2 {
      margin-top: 10px;
    }
  }
}
.search-job {
  &.search-job-xl {
    @include respond('laptop') {
      .search-dropdown {
        border-right: 0 !important;
        margin-bottom: 15px;
      }
    }
    @include respond('tab-land') {
      .search-dropdown {
        border-right: 1px solid $border-color !important;
        margin-bottom: 0;
      }
    }
    @include respond('tab-port') {
      .search-dropdown {
        border-right: 0 !important;
        margin-bottom: 15px;
      }
    }
    @include respond('phone') {
      .search-dropdown {
        border-right: 0 !important;
        margin-bottom: 15px;
        margin-left: 30px;
      }
    }
  }
  @include respond('tab-port') {
    .search-dropdown {
      border-right: 0 !important;
      margin-bottom: 15px;
    }
  }
}
.feature-icon {
  border: 1px solid #dcd7e8;
  @include respond('laptop') {
    svg {
      height: 20px;
      width: 20px;
    }
  }
}
.feature-icon-text {
  @include respond('laptop') {
    span {
      font-size: 25px !important;
    }
  }
}

.activity-card {
  @include respond('tab-land') {
    border-left: 1px solid $border-color;
  }
  @include respond('phone-land') {
    border-left: 0;
  }
}
.portfolios-card > div {
  background: #fbfbfb;
}
.search-dropdown {
  border-right: 1px solid $border-color;
}

.primary-icon {
  path {
    fill: var(--primary);
  }
}
.media-body {
  flex: 1;
}
.default-select {
  &.style-1 {
    width: auto !important;
    height: auto !important;
    left: 0 !important;
  }
  &.style-2 {
    width: 300px !important;
    height: auto !important;
    margin-right: 10px;
    .btn {
      border: 0 !important;
    }
    @include respond('phone') {
      width: 200px !important;
    }
  }
  &.style-3 {
    width: auto !important;
    height: auto !important;
    background: none;
    border: 1px solid var(--primary);
    color: var(--primary);
    .btn {
      background: none;
      color: var(--primary) !important;
    }
  }
}
.ck {
  height: 200px;
}
table.table {
  border-collapse: inherit;
}

.grid-tabs {
  .nav-link {
    padding: 0;
    height: 52px;
    width: 52px;
    line-height: 50px;
    text-align: center;
    border-radius: 52px;
    border: 1px solid var(--primary);
    margin-right: 15px;
    svg path {
      fill: var(--primary);
    }
    &.active {
      background: var(--primary);
      svg path {
        fill: $white;
      }
    }
  }
  .nav-item {
    &:last-child {
      .nav-link {
        margin-right: 0;
      }
    }
  }
  @include respond('laptop') {
    .nav-link {
      height: 40px;
      width: 40px;
      line-height: 38px;
    }
  }
}

.like-btn {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 4px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Font Awesome 5 Free';
  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: block;
    &:checked + .checkmark {
      background: var(--primary);
      &:before {
        color: #fff;
      }
    }
  }
  .checkmark {
    height: 40px;
    text-align: center;
    line-height: 38px;
    width: 40px;
    display: block;
    border: 1px solid var(--primary);
    border-radius: 20px;
    &:before {
      content: '\f02e';
      font-size: 26px;
      font-weight: 900;
      color: var(--primary);
    }
  }
}

.search-row {
  padding: 0 20px;
  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .sub-title {
    font-size: 18px;
  }
  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  @include respond('laptop') {
    .title {
      font-size: 17px;
    }
    .sub-title {
      font-size: 16px;
    }
  }
}
.ps__rail-y {
  padding: 0;
  width: 10px !important;
}
.dropzone .dz-message {
  margin: 0;
}

.image-placeholder {
  position: relative;
  max-width: 130px;
  margin: 10px auto;
  .avatar-edit {
    position: absolute;
    right: -10px;
    z-index: 1;
    top: 0px;
    input {
      display: none;
      + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: #f1f1f1;
          border-color: #d6d6d6;
        }
        &:after {
          content: '\f303';
          font-family: 'Font Awesome 5 Free';
          color: #757575;
          position: absolute;
          top: 4px;
          left: 0;
          right: 0;
          text-align: center;
          margin: auto;
          font-weight: 900;
        }
      }
    }
  }
  .avatar-preview {
    width: 130px;
    height: 95px;
    position: relative;
    border-radius: 30px;
    border: 3px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    & > div {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
.required {
  color: $danger;
}
