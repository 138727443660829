// @import "../../abstracts/variable";

.dz-demo-panel {
    // display: none;
    right: 0;
    position: fixed;
    top: 0;
	width: 90%;
    max-width: 735px;
    background-color: $white;
    height: 100vh;
    transition: all .5s ease-in-out;
	z-index: 9999;
	-webkit-transform: translate(100%,0);
    -ms-transform: translate(100%,0);
    transform: translate(100%,0);
	transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1) 0s;
    .dz-demo-trigger {
        position: absolute;
        z-index: 9;
        bottom: 15rem;
        right: 100%;
        background-color: $secondary;
        color: $white;
        display: flex;
		padding: 14px 18px;
        text-align: center;
		border-radius: 40px 0 0 40px;
		box-shadow: -5px 3px 5px 0px rgba(119, 119, 119, 0.15);
		svg{
			margin-right:15px;
		}
		.close{
			display:none;
		}
        @include custommq($max: 1023px) {
            display:none;
        }
    }
	.dz-demo-close{
		height:30px;
		color:$white;
		width:30px;
		border-radius:$radius;
		background:rgba($black,0.5);
		line-height:30px;
		text-align:center;
	}
    &.show {
        right: 0;
		box-shadow: 0px 0px 50px rgba(0 ,0 ,0 ,0.2);
        z-index: 99999;
		transform: translate(0px, 0px);
		.sidebar-right-trigger{
			//display:none;
		}
		.bg-close{
			position:fixed;
			z-index: -2;
			cursor:pointer;
			width:100%;
			height:100%;
			top:0;
			left:0;
			background:rgba($black,0.15);
		}
		.dz-demo-trigger {
			span{
				display:none;
			}
			.layer{
				display:none;
			}
			.close{
				display:block;
				opacity:1;
			}
		}
    }
	.dz-demo-inner{
		padding: 30px 30px;
		background:$white;
	}
	.dz-demo-content{
		height: calc(100vh - 140px);
		padding-top: 30px;
	}
	.dz-demo-header{
		display:flex;
		justify-content:center;
		align-items:center;
		margin-bottom: 50px;
		h4{
			margin-bottom:0;
			font-weight:700;
			//color:$black;
			font-size:28px;
			background: -webkit-linear-gradient(#40189d, #972ab3);
		  -webkit-background-clip: text;
		  -webkit-text-fill-color: transparent;
		}
	}
	.dz-demo-bx{
		height:200px;
		overflow:hidden;
		//border:5px solid #efefef;
		box-shadow:0px 0px 20px rgba(0,0,0,0.2);
		margin-bottom: 10px;
		&.demo-active{
			border-color:$secondary;
			.overlay-layer{
				opacity: 1;
			}
		}
	}
	.overlay-bx{
		position:relative;
		-webkit-transition: all .3s ease;
		transition: all .3s ease;
		.overlay-layer{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			background-color: rgba(255,255,255,.2);
			-webkit-transition: all .3s ease;
			transition: all .3s ease;
			opacity: 0;
		}
		&:hover{
			transform: translateY(-10px);
			.overlay-layer{
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
				opacity: 1;
			}
		}
	}
}
