[data-theme-version="dark"] {
    .sidebar-right {
        .nav-tabs{
			border-color:#f5f5f5;
			.nav-item{
				.nav-link{
					color:$black;
				}
			} 
		}
		.form-control{
			background:$white;
			color:$black;
			border-color:$border-color;
		}
		.default-select .list{
			background:$white;
			.option{
				&.selected,&.focus,&:hover{
					background:rgba($black,0.05);
				}
			}
		}
		.sidebar-right-inner>h4{
			color:$black!important;
		}
		.bootstrap-select .btn{
			border-color:$border-color!important;
		}
		.bootstrap-select .btn{
			background-color:$white!important;
		}
    }
}