//customize bootstrap
@import './bs-custom';

$strong:#646c9a;
$border: #f0f1f5;
$shadow: 0px 0Epx 40px 0px rgba(82,63,105,0.1);
$body-bg: #F2F2F2;


///////////////
// Solid Color
///////////////
$white: #fff;
$black: #000;
$charade: #23252F;

/////////////////////////
// Dark Version Color
/////////////////////////
$d-ctd: #ddd;
$d-ctl: #828690;
$d-border: #333a54;
$d-bg: #251e35;
$dark-card: #28253b;
$dark_bg_lighter: #1E2A4A;

/////////////////////////
// Primary Light
/////////////////////////
$primary-light: lighten($primary, 57%);
$secondary-light: lighten($secondary, 30%);
$success-light: lighten($success, 38%);
$warning-light: lighten($warning, 38%);
$danger-light: lighten($danger, 30%);
$info-light: lighten($info, 47%);
$dark-light: lighten($dark, 25%);

/////////////////////////
// Opacity 
/////////////////////////
$primary-opacity: rgba($primary, 0.2);
$secondary-opacity: rgba($secondary, 0.5);
$success-opacity: rgba($success, 0.1);
$warning-opacity: rgba($warning, 0.1);
$danger-opacity: rgba($danger, 0.15);
$info-opacity: rgba($info, 0.1);
$dark-opacity: rgba($dark, 0.35);

////////////////////////
// Light Version Color
////////////////////////
$l-ctd: #464a53;
$l-ctl: #828690;
$l-border: #eaeaea;
$l-bg: #f2f4fa;
$heading: #333;

///////////////////
// Material Colors
///////////////////
$dusty-gray: #999999;
$gallery: #EEEEEE;
$gray: #898989;
$input-border-color: $border;


///////////////////
// Social Colors
///////////////////
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #FF0000;
$google-plus: #db4439;
$linkedin: #007bb6;
$instagram: #c32aa3;
$pinterest: #bd081c;
$google: #4285f4;
$snapchat: #fffc00;
$whatsapp: #25d366;
$tumblr: #35465d;
$reddit: #ff4500;
$spotify: #1ed760;
$yahoo: #430297;
$dribbble: #ea4c89;
$skype: #00aff0;
$quora: #aa2200;
$riverBed: #4C585C;
$vimeo: #1ab7ea;


///////////////////
// Misc Colors
///////////////////
$iron: #DDDFE1;
$grey: #D2D6DE;
$pale-sky: #6a707e;
$athensGray: #F0F0F2;
$sky: #2290FF;


//color for coller pallate
$color_pallate_1: $white;
$color_pallate_2: #6610f2;
$color_pallate_3: $primary;
$color_pallate_4: #4d06a5;
$color_pallate_5: #dc3545;
$color_pallate_6:  #fd7e14;
$color_pallate_7: #ffc107;
$color_pallate_8: #5bcfc5;
$color_pallate_9:  #20c997;
$color_pallate_10:  #17a2b8;
$color_pallate_11: #94618E;
$color_pallate_12:#343a40 ;
$color_pallate_13: #2a2a2a;
$color_pallate_14: #4885ed;
$color_pallate_15: #4cb32b;

//utility variables
$radius: 0.625rem; 

:root{
	--primary: #{$primary};
	--secondary: #{$secondary};
	--primary-hover: #{darken($primary, 10)};
	--primary-dark: #{darken($primary, 30)};
	--rgba-primary-1: #{rgba($primary, 0.1)};
	--rgba-primary-2: #{rgba($primary, 0.2)};
	--rgba-primary-3: #{rgba($primary, 0.3)};
	--rgba-primary-4: #{rgba($primary, 0.4)};
	--rgba-primary-5: #{rgba($primary, 0.5)};
	--rgba-primary-6: #{rgba($primary, 0.6)};
	--rgba-primary-7: #{rgba($primary, 0.7)};
	--rgba-primary-8: #{rgba($primary, 0.8)};
	--rgba-primary-9: #{rgba($primary, 0.9)};
}


