// @import "../../abstracts/variable";

.sidebar-right {
    // display: none;
    right: -32rem;
    position: fixed;
    top: 0;
    width: 32rem;
    background-color: $white;
	height:100vh;
    margin-top: 0;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1) 0s;
	z-index: 9999;
	.bg-label-pattern{
		background: transparent;		
		background-size: 130%;
	}
	.bootstrap-select{
		height:48px;
		border-radius: 6px;
		.btn{
		    padding:12px 15px;
			font-size:15px;
			border-color:#d1d1d1;
			border-radius: 6px;
		}
	}
	.sidebar-right-inner{
		background:$white;
	}
    .slimScrollDiv {
        @at-root [direction="rtl"] #{&} {
            overflow: visible !important;
        }
    }
    .sidebar-right-trigger {
        position: absolute;
        z-index: 9;
        bottom: 10.5rem;
		padding: 13px 18px;
		align-items: center;
        right: 100%;
        background-color: var(--primary);
        color: $white;
        display: flex;
        text-align: center;
		border-radius: 50px 0 0 50px;
		box-shadow: -5px 3px 5px 0px rgba(119, 119, 119, 0.15);
		
		@at-root [data-theme-version="dark"] #{&} {
			color: $white;
        }
        @include custommq($max: 1023px) {
            display:none;
        }
		span{
			margin-right: 15px;
			&.text{
				margin-right:0;
			}
		}
		i{
			font-size: 25px;
		}
    }
	.sidebar-close-trigger{
		position:absolute;
		z-index: 2;
		font-size: 28px;
		top:0;
		right:-48px;
		height: 3rem;
        width: 3rem;
		line-height:3rem;
		text-align:center;
		background:rgba($black,1);
		color:$white;
	}
	

    &.show {
        right: 0;
		box-shadow: 0px 0px 50px rgba(0 ,0 ,0 ,0.2);
        z-index: 99999;
		.sidebar-right-trigger{
			.text{
				display:none;
			}
			span{
				margin-right: 0px;
			}
		}
		.bg-overlay{
			position:fixed;
			width:100%;
			cursor:pointer;
			height:100%;
			top:0;
			left:0;
			background:rgba($black,0.2);
		}
    }
	.card-tabs{
		.nav-tabs {
			justify-content: space-between;
			position: sticky;
			top: 0;
			width:100%;
			background-color: $white;
			z-index: 2;
			@at-root [data-theme-version="dark"] #{&} {
			  
			}
			

			.nav-item {
				margin-bottom: 0;
				flex: 1;

				.nav-link {
					border: 0;
					font-size: 1.125rem;
					position: relative;
					text-align: center;
					background-color: $white; 
					@at-root [data-theme-version="dark"] #{&} {
					   
					}

					&.active {
						
						@at-root [data-theme-version="dark"] #{&} {
							border-right: none;
							border-left: none;
							border-top: none;
						}
					}
				}
			}
		}
	}
	.sidebar-right-inner > h4{
			padding: 10px 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			//color: $black;
			font-size: 25px;
			font-weight:700;
			background: $white;
			margin: 0px 0px 30px 0px;
			background: -webkit-linear-gradient(#40189d, #972ab3);
		  -webkit-background-clip: text;
		  -webkit-text-fill-color: transparent;
		  a{
			background: var(--primary);
		  -webkit-background-clip: unset;
		  -webkit-text-fill-color: #fff;
		  }
	}


	.admin-settings {
		height: calc(100% - 100px);
		padding: 0 20px;
		.row > div {
			margin-bottom: 20px;
		}
		p {
			color: #353535;
			font-weight: 500;
			margin-bottom: 8px;
			font-size: 16px;
		}

		//All color switcher scss goes here
		input[type="radio"] {
			display: none;

			+ label {
				display: inline-block;
				width: 35px;    
				height: 35px;
				cursor: pointer;
				transition: all .1s ease;
				border-radius: 4px;
				margin-right: 5px;
				margin-bottom: 3px;
			}

			&:checked {
				+ label {
					position: relative;

					&::after {
						height: 33px;
						width: 33px;
						left: -4px;
						top: -4px;
						content: "";
						position: absolute;
						background-color: inherit;
						border-radius: 6px;
						opacity: 0.4;
					}
				}
			}
		}
	}
   
    #nav_header, 
    #header, 
    #sidebar,
	#primary{


        @each $name, $color in $theme_backgrounds {
            &_#{$name} {
                + label {
                    background-color: $color;            
                }
            }
        }

        &_color_1 {
            + label {
                border: 1px solid #c4c4c4;
            }
        }
    }
	
	&.style-1{
		height:100vh;
		width:250px;
		margin-top:0;
		right:-250px;
		.sidebar-right-inner{
			background:$white;
		}
		.sidebar-right-trigger {
			top:12.4rem;
		}
		&.show{
			right:0;
			.sidebar-right-trigger{
				display:block;
			}
		}
	}
}

@keyframes bounce {
	0% {
        transform: translateX(-8%);;
        -webkit-transform: translateX(-8%);; 
        }
	50% {
        transform: translateX(8%);;
        -webkit-transform: translateX(8%);; 
        }
	100% {
        transform: translateX(-8%);; 
        -webkit-transform: translateX(-8%);; 
        }
}

@-webkit-keyframes bounce {
	0% {
        transform: translateX(-8%);;
        -webkit-transform: translateX(-8%);; 
        }
	50% {
        transform: translateX(8%);;
        -webkit-transform: translateX(8%);; 
        }
	100% {
        transform: translateY(-8%);; 
        -webkit-transform: translateY(-8%);; 
        }
}