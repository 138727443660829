
@import "../../../vendor/bootstrap-v4-rtl/scss/functions";
@import "../../../vendor/bootstrap-v4-rtl/scss/variables";
@import "../../../vendor/bootstrap-v4-rtl/scss/mixins";
@import "../../../vendor/bootstrap-v4-rtl/scss/rtl";

.rtl {
    
	
  // stylelint-disable declaration-no-important
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @each $prop, $abbrev in (margin: m, padding: p) {
        @each $size, $length in $spacers {

        //   .#{$abbrev}r#{$infix}-#{$size} {
        //     #{$prop}-right: 0!important;
        //     #{$prop}-left: $length !important;
        //   }

          .#{$abbrev}x#{$infix}-#{$size} {
            #{$prop}-right: auto;
            #{$prop}-left: $length !important;
          }

        //   .#{$abbrev}l#{$infix}-#{$size} {
        //     #{$prop}-left: 0!important;
        //     #{$prop}-right: $length !important;
        //   }

          .#{$abbrev}x#{$infix}-#{$size} {
            #{$prop}-left: auto;
            #{$prop}-right: $length !important;
          }
        }
      }

      .mr#{$infix}-auto {
        margin-right: 0!important;
        margin-left: auto !important;
      }
      .mx#{$infix}-auto {
        margin-right: auto!important;
        margin-left: auto !important;
      }
      .ml#{$infix}-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
      }
      .mx#{$infix}-auto {
        margin-right: auto !important;
        margin-left: auto!important;
      }
    }
  }


  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      .float#{$infix}-left  { @include float-right; }
      .float#{$infix}-right { @include float-left; }
      .float#{$infix}-none  { @include float-none; }
    }
  }

}




  //global styles
  [direction="rtl"] {

      &[data-theme-version="dark"] {
          .border, .border-left, .border-right {
              border-color: $d-border !important;
          }
      }

      /* .text-right {
          text-align: left !important;
      }

      .text-left {
          text-align: right !important;
      } */

      .border-right {
          border-left: 1px solid $border !important;
          border-right: 0 !important;

      }

      .border-left {
          border-right: 1px solid $border !important; 
          border-left: 0 !important;
      }

      .dropdown-menu {
          left: auto;
      }

      .dropdown-menu-right {
			left: 0;
			right: auto;
			@include respond('phone') {
				left: 15px;
			}
      }
	  .notification_dropdown .dropdown-menu-right .media > span{
	      margin-left: 10px;
	      margin-right: 0;
	  }
	  
  }
